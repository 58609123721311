import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PublicRoute } from "core/route.public";



import NoPage from 'components/NoPage';
import Layout from "Layout";
import i18n from "i18next";

const Login = lazy(() => {
  return Promise.all([
    import(/* webpackChunkName: "LoginComponent" */ './components/login/Login'),
    new Promise(resolve => setTimeout(resolve, 300))  // lazy test 
  ])
    .then(([moduleExports]) => moduleExports);
})
import Loading from "Loading";

class App extends React.Component {

  constructor(props: any) {
    super(props);

    let lang = localStorage.getItem("lang");
    if (!lang) {
      lang = window.navigator.language.substring(0, 2);
      localStorage.setItem("lang", lang);
    }
    i18n.changeLanguage(lang);

    console.log('lang', lang);

  }

  public render() {

    return (
      <Routes>

        <Route path='/404' element={<NoPage />}></Route>

        <Route path="*" element={<Layout />} />

        <Route path='/login' element={
          <PublicRoute>
            <React.Suspense fallback={<Loading />}><Login /></React.Suspense>
          </PublicRoute>
        }></Route>

      </Routes>
    );
  }

}

export default App;
