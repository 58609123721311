import NavBar from 'components/NavBar';
import SideBar from 'components/SideBar';
import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import NoPage from 'components/NoPage';
import Temp from 'components/test/Temp';
import { ProtectedRoute } from "core/route.protected";
import { PublicRoute } from "core/route.public";
import Loading from "Loading";

import authService from 'services/auth.service';

const Instagram = lazy(() => import(`./components/instagram/Instagram`));
const Main = lazy(() => import(`./components/main/Main`));

const Login = lazy(() => {
  return Promise.all([
    import(/* webpackChunkName: "LoginComponent" */ './components/login/Login'),
    new Promise(resolve => setTimeout(resolve, 1000))
  ])
    .then(([moduleExports]) => moduleExports);
})

class Layout extends React.Component {

  constructor(props: any) {
    super(props);

    for (let i = 0; i < document.body.classList.length; i++) {
      document.body.classList.remove(document.body.classList[0]);
    }
    document.body.style.minHeight = null;

    // document.body.classList.add("sidebar-mini");
    // document.body.classList.add("layout-fixed");
    // document.body.classList.add("layout-navbar-fixed");


  }

  public render() {

    return (
      <div className="wrap">
        
        <NavBar />

        <Routes>
          {/* <Route path='/' element={<Home />}></Route> */}

          <>
            <Route path='/' element={
              <PublicRoute>
                <React.Suspense fallback={<Loading />}><Main /></React.Suspense>
              </PublicRoute>
            }></Route>

            <Route path='/instagram/*' element={
              <PublicRoute>
                <React.Suspense fallback={<Loading />}><Instagram /></React.Suspense>
              </PublicRoute>
            }></Route>
            
            {/* <Route path='/instagram/*' element={
              <PublicRoute>
                <React.Suspense fallback={<Loading />}><Instagram /></React.Suspense>
              </PublicRoute>
            }></Route> */}


            {/* <Route path='/temp' element={<Temp />}></Route>

            <Route path='/login' element={
              <PublicRoute>
                <React.Suspense fallback={<Loading />}><Login /></React.Suspense>
              </PublicRoute>
            }></Route> */}

            {/* <Route path='/device/*' element={
                  <ProtectedRoute>
                    <React.Suspense fallback={<Loading />}><Device /></React.Suspense>
                  </ProtectedRoute>
                }></Route> */}

          </>


          <Route path='*' element={<NoPage />}></Route>
        </Routes>

      </div>

    );

  }

}

export default Layout;
