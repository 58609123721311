import React from 'react';


function NoPage() {

        
        return (
            
          <h1>
            {/* 404 */}
          </h1>

        );

}

export default NoPage;