
function deepCopy(obj: any): any {

    return JSON.parse(JSON.stringify(obj));

}

function handleChange(e: any, name: string, setParams: Function, params: any): void {

    // console.log(e , e.target.type);

    if (e.target.type == "checkbox") {

        // console.log(e.target.checked);
        setParams(() => ({
            ...params,
            [name]: e.target.checked
        }));

    } else {
        // console.log("handleChange", e.target.name , e.target.value);

        const depth = name.split(".").length;
        if (depth == 3) {

            let container = name.split(".")[0];
            let group = name.split(".")[1];
            let key = name.split(".")[2];

            let newContainer = params[container];

            let newObj = newContainer[group];

            newObj[key] = e.target.value;

            setParams(() => ({
                ...params,
                [container]: newContainer
            }));


        } else if (depth == 2) {

            let group = name.split(".")[0];

            // console.log("group", group)
            let key = name.split(".")[1];

            let newObj = params[group];
            newObj[key] = e.target.value;

            setParams(() => ({
                ...params,
                [group]: newObj
            }));

        } else {

            setParams(() => ({
                ...params,
                [name]: e.target.value
            }));

        }


    }


};

function handleApiError(e: any): void {

    // if (e.code == "ERR_CANCELED" /*사용자 요청 취소*/|| e.code == "ERR_BAD_REQUEST"/*401*/){      // 
    //     return;
    // }

    if (e.code == "ERR_CANCELED" /*사용자 요청 취소*/) {      // 
        return;
    }

    if (e.code == "ERR_BAD_REQUEST"/*401*/ && !e.response.data.message) {
        return;
    }

    // console.log(e, e.code);
    
    if (e.response && e.response.data && e.response.data.message) {
        alert(e.response.data.message);
    } else {
        //alert(e.response.data.error);
    }

    if (e.code == "ERR_BAD_REQUEST"/*401*/ && !e.response.data.err_msg) {
        return;
    }

    if (e.response && e.response.data && e.response.data.err_msg) {
        alert(e.response.data.err_msg);
    } else {
        //alert(e.response.data.error);
    }
}

export default { handleChange, handleApiError, deepCopy };