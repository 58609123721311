import React, { useEffect, useRef, useState } from 'react';
import pageUtil from "utils/Page.util";

export class ToastData {

    visible: boolean = false;

    items: any = [];

}

class Params {
    toastData: ToastData
}

function Toast(props: any) {

    const [params, setParams] = useState(new Params());
    const closeModalToParent = useRef(null);
    const mounted = useRef(false);

    // onMount
    useEffect(() => {

        const toastData = props['toastData'];
        if (props['onClose'])
            closeModalToParent.current = props['onClose'];

        setParams(() => ({
            toastData: toastData
        }));

    }, []);

    //onUpdate
    useEffect(() => {

        if (!mounted.current) {
            mounted.current = true;
        } else {

            setParams(() => ({
                toastData: props["toastData"]
            }));

        }

    }, [props["toastData"]]);


    function close(item): void {

        const passParams = pageUtil.deepCopy(params) as Params;

        passParams.toastData.items.find(p => p.id == item.id).visible = false;

        if(passParams.toastData.items.filter(p => p.visible).length < 1) passParams.toastData.visible = false;

        setParams((prev) => ({
            ...prev,
            toastData: passParams.toastData
        }));

        if (closeModalToParent.current)
            (closeModalToParent.current as Function)();

    }

    return (
        <>
            {params.toastData && params.toastData.visible && (
                <div className="toasts-top-right fixed" style={{ width: "300px", height: "50px", right:"45%" }}>
                    <>
                        {
                            params.toastData.items.map((item, index) => (
                                <React.Fragment key={item.id}>
                                    {item.visible && (
                                        <div className="toast fade show" role="alert" aria-live="assertive" aria-atomic="true" >
                                            <div className="toast-header">
                                                <strong className="mr-auto">{item.title}</strong>
                                                {/* <strong className="mr-auto">Toast Title</strong> */}

                                                <button data-dismiss="toast" type="button" className="ml-2 mb-1 close" aria-label="Close">
                                                    <span aria-hidden="true" onClick={() => {close(item)}}>×</span>
                                                </button>
                                            </div>
                                            <div className="toast-body">{item.content}</div>
                                            {/* <div className="toast-body">Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</div> */}
                                        </div>
                                    )}
                                </React.Fragment>
                            ))
                        }
                    </>
                </div>
            )}
        </>
    );


}

export default Toast;