import React from 'react';


class Loading extends React.Component{

    public render() {
        
        return (
            
          <div>loading!...</div>

        );
    }

}

export default Loading;