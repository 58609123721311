import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import en from './locales/en';
import en from './locales/en.json';
import ko from './locales/ko.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
        en:en,
        ko:ko
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n